<template>
  <div class="login-container">
    <div class="layer">
      <div class="some-space">
        <div class="form">
          <h2>共赢在海马管理系统</h2>
          <div class="item">
           
            <svg-icon name="user" class="icons" />
            <input
              autocomplete="off"
              type="text"
              class="input"
              v-model="userName"
              placeholder="请输入用户名"
            />
             <!-- <el-select v-model="baseUrl" @change="changeBaseUrl">
              <el-option value="hm" label="海马运动"></el-option>
              <el-option value="partner" label="陪练"></el-option>
            </el-select> -->
          </div>
          <div class="item">
            <svg-icon name="lock" class="icons" />
            <input
              autocomplete="off"
              type="password"
              class="input"
              v-model="userPwd"
              maxlength="20"
              @keyup.enter="login"
              placeholder="请输入密码"
            />
          </div>
          <button class="loginBtn" :disabled="logining" @click.stop="login()">
            {{ logining ? "正在登录" : "立即登录" }}
          </button>
          <!-- <div class="tip">默认用户名：admin ，默认密码：123456</div> -->
        </div>
        <div class="footer">
          <div>
            Copyright© 2021 杭州汇合智能科技有限公司
            <a
              target="_blank"
              href="https://beian.miit.gov.cn/#/Integrated/index"
              >浙ICP备14030516号-9</a
            >
          </div>
        </div>
      </div>
    </div>

    <bgAnimation />

  </div>
</template>

<script>
import bgAnimation from "./components/index.vue";
export default {
  name: "Login",
  components: {
    bgAnimation,
  },
  data() {
    return {
      userName: "",
      userPwd: "",
      baseUrl: "hm",
      visible: false,
      type: "",
      logining: false,
      managerInfo:{}
    };
  },
  computed: {
    isLoginAble() {
      return !(this.userName && this.userPwd);
    },
  },
  created() {
    this.userName = localStorage.getItem("userName") || "";
     localStorage.removeItem("timSign")
  },
  mounted() {
  },
  methods: {
    changeBaseUrl(v) {
      if (v == "partner") {
        this.$axios.defaults.baseURL = "https://wjcs.site/qmore/";
      } else if (v == "hm") {
        this.$axios.defaults.baseURL = "https://curiousmore.com/qmore/"; /* 线上 */
      }
    },
    getTimSign() {
      this.$get("user/user/getSign", {
        user_id: this.managerInfo.user_id,
      }).then((res) => {
        localStorage.setItem("timSign", res.data.data);
         this.$tim
            .login({
              userID: "" + this.managerInfo.user_id,
              userSig: localStorage.getItem("timSign"),
            })
            .then((res) => {
              this.$store.commit("timLogin", true);
            });
      });
    },
    login() {
      if (this.userPwd && this.userName) {
        this.logining = true;
        this.$axios
          .post("/authorization/managerUser/login", {
            login_name: this.userName,
            pwd: this.userPwd,
          })
          .then((res) => {
            localStorage.setItem("userName", this.userName);
            // console.log(res)
            if (res.data.code == 0) {
              this.$message("登录成功");
              this.logining = false;
              let managerInfo = res.data.data.managerInfo;
              this.managerInfo = managerInfo
              this.$store.commit("setAdmin_name", managerInfo.name);
              localStorage.setItem("token", res.data.data.managerToken);
              localStorage.setItem("managerInfo", JSON.stringify(managerInfo));
              localStorage.setItem("mechanism_id", managerInfo.mechanism_id);
              localStorage.setItem("islogin", managerInfo.id);
              localStorage.setItem("type", managerInfo.type);

              this.$axios.defaults.headers["token"] =
                localStorage.getItem("token");
              if(managerInfo.user_id){
                this.getTimSign()
              }
              if(managerInfo.cooperator_id){
                  this.$axios({
                    url: "/user/cooperator/findById",
                    params: {
                      id: managerInfo.cooperator_id,
                    },
                  }).then(res=>{
                         localStorage.setItem("cooperator", JSON.stringify(res.data.data));
                  })
              }


              //获取账号权限
              this.$store.dispatch("getUserPermission");
              // 获取代理商的代理区域
              switch (managerInfo.type) {
                case "agent":
                case "sub_agent":
                case "agent_company":
                  this.$axios({
                    url: "/user/agentArea/query",
                    params: {
                      agent_id: managerInfo.cooperator_id || "-1",
                    },
                  }).then((res) => {
                    if (res.data.data.length > 0) {
                      localStorage.setItem(
                        "agentAreas",
                        JSON.stringify(res.data.data)
                      );
                      let cur_area = {};
                      const area1 = res.data.data[0];
                      if (area1.type === "province") {
                        cur_area.province = area1.province;
                      } else if (area1.type === "city") {
                        cur_area.province = area1.province;
                        cur_area.city = area1.city;
                      } else if (area1.type === "district") {
                        cur_area.province = area1.province;
                        cur_area.city = area1.city;
                        cur_area.district = area1.district;
                      }
                      localStorage.setItem(
                        "cur_agentAreas",
                        JSON.stringify(cur_area)
                      );
                      this.getRoleMenuList();
                    } else {
                      this.$message("您还未开通代理区域");
                    }
                  });
                  break;

                default:
                  this.getRoleMenuList();
                  break;
              }
            } else {
              this.logining = false;
              this.$message({ type: "error", message: res.data.message });
            }
          })
          .catch((rej) => {
            this.logining = false;
            this.$message({ type: "error", message: rej.data.message });
          });
      } else {
        console.log("we");
        this.$message({ type: "error", message: "请输入用户名和密码" });
      }
    },
    //获取账号菜单列表
    getRoleMenuList() {
      this.$store.dispatch("getRoleMenu").then((res) => {
        if (res.length > 0) {
          let name = res[0].secNavL[0].url.replace("/", "");
          this.$router.push({
            name,
          });
        } else {
          this.$router.push({
            name: "admininfoCenter",
          });
        }
      });
    },

    // login(){
    //   this.$router.push('/main')
    // },
    confirm() {
      this.visible = false;
      // console.log("点击确定");
    },
  },
};
</script>

<style lang="less" scoped>
.login-container {
  .layer {
    position: absolute;
    height: 100vh;
    width: 100vw;
  }

  #particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .some-space {
    color: white;
    font-weight: 100;
    letter-spacing: 2px;
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    z-index: 1001;

    .form {
      width: 460px;
      height: auto;
      background: rgba(36, 36, 85, 0.5);
      margin: 0 auto;
      padding: 35px 30px 25px;
      box-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
      border-radius: 10px;

      @media screen and(max-width: 768px) {
        width: 80%;
      }

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        border-bottom: 1px solid #d3d7f7;

        i {
          color: #d3d7f7;
          margin-right: 10px;
        }
      }

      h2 {
        text-align: center;
        font-weight: normal;
        font-size: 26px;
        color: #d3d7f7;
        padding-bottom: 35px;
      }

      .input {
        font-size: 16px;
        line-height: 30px;
        width: 100%;
        color: #d3d7f7;
        outline: none;
        border: none;
        background-color: rgba(0, 0, 0, 0);
        padding: 10px 0;
      }

      .loginBtn {
        width: 100%;
        padding: 12px 0;
        border: 1px solid #d3d7f7;
        font-size: 16px;
        color: #d3d7f7;
        cursor: pointer;
        background: transparent;
        border-radius: 4px;
        margin-top: 10px;

        &:hover {
          color: #fff;
          background: #0090ff;
          border-color: #0090ff;
        }
      }

      .tip {
        font-size: 12px;
        padding-top: 20px;
      }
    }

    .footer {
      // width: 100%;
      text-align: center;
      margin: 0 auto;
      margin-top: 200px;

      a {
        color: white;
      }
    }
  }
}

input::-webkit-input-placeholder {
  color: #d3d7f7;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d3d7f7;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d3d7f7;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d3d7f7;
}

.icons {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

@-ms-keyframes cloud {
  0% {
    -ms-transform: translate(-50%, -50%);
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    -ms-transform: translate(-50%, -40%);
  }
}

@-moz-keyframes cloud {
  0% {
    -moz-transform: translate(-50%, -50%);
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    -moz-transform: translate(-50%, -40%);
  }
}

@-o-keyframes cloud {
  0% {
    -o-transform: translate(-50%, -50%);
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    -o-transform: translate(-50%, -40%);
  }
}

@-webkit-keyframes cloud {
  0% {
    -webkit-transform: translate(-50%, -50%);
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(-50%, -40%);
  }
}

@keyframes cloud {
  0% {
    transform: translate(-50%, -50%);
  }

  40% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -40%);
  }
}
</style>
